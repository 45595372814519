@charset "UTF-8";
/**
* Indiquer la liste des variables de couleurs
* Pour nommer la couleur au sens littéral, ajouter un commentaire : "// name: Nom de la couleur"
* <!> Si aucun name n'est donné, la génération de class (.<color> / .bg-<color>) se fera après compilation des assets <!>
* <!> Mais le background ne sera pas disponible pour les sections <!>
* Ne pas changer l'indentation et la syntaxe des lignes
*
* Syntaxe : $color: color<hex/rgba/hsl>; // name: Nom de la couleur
*/
/**
* <!> Ajouter la nouvelle couleur dans $color-map pour qu'elle soit disponible en tant que class CSS <!>
* Les paramètres sont :
* - base : la couleur de base
* - font-color : la couleur de police
* - with-color-class : true/false | true si on veut générer une classe CSS pour la couleur de police
*/
.bg-white {
  background-color: #FFFFFF;
  --colorContent: #464754;
  color: #464754;
}

.bg-lightgrey {
  background-color: #E7E7E7;
  --colorContent: #464754;
  color: #464754;
}

.bg-grey {
  background-color: #BDBDBD;
  --colorContent: #464754;
  color: #464754;
}

.bg-darkgrey {
  background-color: #464754;
  --colorContent: #FFFFFF;
  color: #FFFFFF;
}

.bg-black {
  background-color: #00064B;
  --colorContent: #FFFFFF;
  color: #FFFFFF;
}

.bg-lightblue {
  background-color: #E8EFF8;
  --colorContent: #464754;
  color: #464754;
}

.bg-lightblue-2 {
  background-color: #CCE1FE;
  --colorContent: #464754;
  color: #464754;
}

.bg-lightblue-3 {
  background-color: #A6CAFE;
  --colorContent: #464754;
  color: #464754;
}

.bg-blue {
  background-color: #0069FC;
  --colorContent: #FFFFFF;
  color: #FFFFFF;
}

.blue {
  color: #0069FC;
}

.bg-blue-2 {
  background-color: #0458CD;
  --colorContent: #FFFFFF;
  color: #FFFFFF;
}

.blue-2 {
  color: #0458CD;
}

.bg-darkblue {
  background-color: #000B96;
  --colorContent: #FFFFFF;
  color: #FFFFFF;
}

.bg-darkblue-2 {
  background-color: #040C7C;
  --colorContent: #FFFFFF;
  color: #FFFFFF;
}

.darkblue-2 {
  color: #040C7C;
}

.bg-transparent {
  background-color: rgba(255, 255, 255, 0);
  --colorContent: #464754;
  color: #464754;
}

[class^=bg-],
[class*=bg-] {
  position: relative;
}

body.login {
  background-color: #E8EFF8;
}
body.login h1 a {
  background-size: contain;
  background-image: url(/wp-content/themes/sodalec/dist/images/sodalec-logo.svg);
}

#login {
  --color: #0069FC;
  --borderColor: #0458CD;
}
#login .button-primary {
  background-color: var(--color);
  border: solid 2px var(--borderColor);
}
#login a,
#login #nav a:hover,
#login #backtoblog a:hover,
#login .button-secondary {
  color: var(--color);
}
#login #login_error,
#login .message,
#login .success {
  border-left-color: var(--color);
}
#login .button.wp-hide-pw:focus,
#login input:focus {
  border-color: var(--color);
  box-shadow: 0 0 0 1px var(--color);
}
#login form {
  border-radius: 8px;
  border: solid 2px #E7E7E7;
}